<template>
	<div class="sequence-card">
		<div v-if="state.code > 1" class="badge-container" :title="state.label">
			<div class="badge" :class="{
				'missed': (state.code == 2),
				'done': (state.code == 3)
			}">
				<img v-if="state.code == 2" src="@/assets/images/warning-icon.svg">
				<img v-if="state.code == 3" src="@/assets/images/checkmark-icon.svg">
			</div>
		</div>
		
		<p class="text-lg">Séquence {{sequence.order + 1}}{{(sequence.title ? ' : ' + sequence.title : '')}}</p>
		<p class="sequence-date">{{formatDate(date)}}</p>

		<button
		v-if="state.button && isEditable"
		:class="{
			'ars-button-blue': (state.code == 1),
			'ars-button-orange': (state.code == 0),
			'ars-button-red': (state.code == 2)
		}"
		:disabled="!isEditable"
		@click="$emit('edit')">
			{{state.button}}
		</button>
	</div>
</template>

<script>	
	export default {
		name: 'SequenceCard',
		props: {
			sequence: {
				type: Object,
				required: true
			},
			isEditable: {
				type: Boolean,
				required: true
			},
			userSequence: {
				type: Object,
				required: false,
				default: null
			},
			sequenceEndLog: {
				type: Object,
				required: false,
				default: null
			}
		},
		computed: {
			date() {
				if (!this.userSequence)
					return null

				return new Date(this.userSequence.start_date)
			},
			state() {
				const startOfDay = (new Date()).setHours(0, 0, 0, 0)
				const isPast = (this.date ? this.date.getTime() < startOfDay : false)

				return (this.sequenceEndLog ? { code: 3, label: 'Terminé' } : (isPast ? { code: 2, label: 'Manqué', button: 'Reprogrammer' } : (this.userSequence ? { code: 1, label: 'Programmé', button: 'Changer la date' } : { code: 0, label: 'Non programmé', button: 'Choisir une date' })))
			}
		},
		methods: {
			formatDate(dateString) {
				if (!dateString)
					return '../../....'

				const date = new Date(dateString)
				const parts = [date.getDate(), date.getMonth() + 1, date.getFullYear()]

				return parts.map((part) => ((part < 10 ? '0' : '') + part)).join('/')
			}
		}
	}
</script>

<style scoped lang="scss">
.sequence-card {
	@apply relative bg-white sm:rounded-2.5xl sm:pt-8 pb-10 sm:px-10;

	.badge-container {
		@apply absolute w-10 h-10 bg-white rounded-full -top-2 -right-2 p-2;

		.badge {
			@apply flex justify-center items-center w-full h-full rounded-full p-1 cursor-help;

			img {
				max-height: 100%;
			}

			&.missed {
				@apply bg-red;
			}

			&.done {
				@apply bg-green-alt;
			}
		}
	}

}

p.sequence-date {
	@apply font-medium italic mb-6;
}
</style>
