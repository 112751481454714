import { apollo } from '@/apolloClient'

import { GC_USER_ID } from '@/constants/jwt'

import { GC_ADD_USER_SEQUENCE, GC_UPDATE_USER_SEQUENCE } from '@/graphql/userSequence'

export default {
	namespaced: true,
	actions: {
		async add(context, { promotionId, sequenceId, startDate, endDate }) {
			const currentUserId = localStorage.getItem(GC_USER_ID)

			if (!currentUserId)
				return false

			const userSequence = {
				promotion_id: promotionId,
				sequence_id: sequenceId,
				user_id: currentUserId,
				start_date: startDate,
				end_date: endDate
			}

			try {
				// Add remote data
				const response = await apollo.mutate({
					mutation: GC_ADD_USER_SEQUENCE,
					variables: {
						userSequence
					}
				})
			} catch (err) {
				return false
			}

			return true
		},
		async update(context, { promotionId, sequenceId, oldStartDate, startDate, endDate }) {
			const currentUserId = localStorage.getItem(GC_USER_ID)

			if (!currentUserId)
				return false

			const userSequence = {
				start_date: startDate,
				end_date: endDate
			}

			try {
				// Update remote data
				const response = await apollo.mutate({
					mutation: GC_UPDATE_USER_SEQUENCE,
					variables: {
						promotionId,
						sequenceId,
						userId: currentUserId,
						oldStartDate,
						userSequence
					}
				})
			} catch (err) {
				return false
			}

			return true
		}
	}
}