<template>
	<div class="promotion-header mb-14">
		<h2 class="font-medium text-lg mb-4">
			<span class="uppercase">{{promotion.course.name}}</span>
			du
			<span class="italic">{{formatDate(this.promotion.start_date)}}</span>
			au
			<span class="italic">{{formatDate(this.promotion.end_date)}}</span>
		</h2>

		<div class="help sm:bg-gray-alt rounded-2.5xl sm:py-6 sm:px-10 lg:w-7/12">
			<p>Dans chaque niveau, choisissez les dates de vos séquences.</p>
			<p>Pour programmer vos séquences, cliquez sur les boutons et sélectionnez la date dans le calendrier.<span class="font-medium"> Vous pouvez programmer jusqu’à 2 séquences par jour.</span></p>
			<p>En cas de problème, contactez-nous <a href="mailto:aboubacar.diallo@afpols.fr">aboubacar.diallo@afpols.fr</a></p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PromotionHeader',
		props: {
			promotion: {
				type: Object,
				required: true
			}
		},
		methods: {
			formatDate(dateString) {
				const date = new Date(dateString)
				const parts = [date.getDate(), date.getMonth() + 1, date.getFullYear()]

				return parts.map((part) => ((part < 10 ? '0' : '') + part)).join('/')
			}
		}
	}
</script>

<style scoped lang="scss">
.help p {
	@apply mb-2;
}
</style>
