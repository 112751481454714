<template>
	<div class="level-chooser flex flex-wrap mb-12" v-if="orderedLevels.length > 1">
		<button
		v-for="level in orderedLevels"
		:key="level.id"
		:class="{ selected: (selectedLevel && level.id == selectedLevel.id) }"
		@click="$emit('choose', level)">
			Niveau {{level.order + 1}}
		</button>
	</div>
</template>

<script>
	export default {
		name: 'LevelChooser',
		model: {
			prop: 'selectedLevel',
			event: 'choose'
		},
		props: {
			course: {
				type: Object,
				required: true
			},
			selectedLevel: {
				type: Object,
				required: false,
				default: null
			}
		},
		computed: {
			orderedLevels() {
				// Sort a copy of levels array by order
				let levels = this.course.levels.slice().sort((a, b) => {
					return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
				})

				return levels
			}
		},
		watch: {
			orderedLevels: {
				handler(levels) {
					// Automatically select first level
					if (levels && levels.length > 0) {
						this.$emit('choose', levels[0])
					}
				},
				immediate: true
			}
		}
	}
</script>

<style scoped lang="scss">
button {
	@apply text-gray-light uppercase text-lg font-medium border-b-2 border-gray px-2 py-4 w-1/3;

	&.selected {
		@apply border-red text-black;
	}

	@screen sm {
		@apply flex-1 pt-0 w-auto;
	}
}
</style>
