<template>
	<div class="welcome-header flex-grow mb-4 lg:mb-0 lg:mr-2">
		<h1 class="text-2xl mb-1 lg:mb-0">Bonjour {{userInfo.first_name}} {{userInfo.name}}</h1>
		<p>Bienvenue sur votre espace de planification des séquences digitales du serious game</p>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'WelcomeHeader',
		computed: {
			...mapState('Auth', ['userInfo'])
		}
	}
</script>

<style scoped lang="scss"></style>
