import Vue from 'vue'
import Vuex from 'vuex'

import PLATFORMS from "@/constants/platforms"

// Modules
import Auth from './authentication'
import UserSequence from './userSequence'

Vue.use(Vuex)

const storedPlateformSlug = localStorage.getItem('platform_slug')
const storedPlateform = (storedPlateformSlug && PLATFORMS.find((platform) => (platform.slug === storedPlateformSlug)))

export default new Vuex.Store({
  state: {
    currentPlatform: storedPlateform || null,
  },
  mutations: {
    SET_PLATFORM(state, newPlatform) {
      state.currentPlatform = newPlatform
    }
  },
  modules: {
    Auth,
    UserSequence
  }
})
