<template>
	<div class="agenda">
		<PromotionHeader :promotion="promotion" />
		<LevelChooser
		:course="promotion.course"
		v-model="selectedLevel" />
		<Sequences
		v-if="selectedLevel"
		:promotion="promotion"
		:level="selectedLevel" />
	</div>
</template>

<script>
	import PromotionHeader from "@/components/Agenda/PromotionHeader"
	import LevelChooser from "@/components/Agenda/LevelChooser"
	import Sequences from "@/components/Agenda/Sequences"

	export default {
		name: 'Agenda',
		components: {
			PromotionHeader,
			LevelChooser,
			Sequences
		},
		props: {
			promotion: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				selectedLevel: null
			}
		}
	}
</script>

<style scoped lang="scss"></style>
