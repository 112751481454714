<template>
    <div class="authentication h-screen flex items-center justify-center">
        <div class="authentication-box rounded-lg flex flex-col items-center justify-center">
            <img class="authentication-logo h-24" src="@/assets/images/logo.svg">

            <div
            class="authentication-form flex flex-col items-center justify-evenly"
            @keyup.enter="submit">
                <div class="w-full text-right">
                    <div class="authentication-inputs">
                        <v-select
                        name="promotions"
                        label="title"
                        placeholder="Formations"
                        :clearable="false"
                        :options="PLATFORMS"
                        v-model="platform" />
                        <input type="text" name="email" placeholder="Email" v-model="form.email">
                        <input type="password" name="password" placeholder="Mot de passe" v-model="form.password">
                    </div>
                </div>

                <div v-if="error" class="text-red text-sm">
                    {{ error }}
                </div>

                <button
                type="submit" 
                class="authentication-submit ars-button-blue mt-6"
                :class="{
                    'loading': hasPendingRequest
                }"
                :disabled="hasPendingRequest"
                @click.prevent="submit">
                    Se connecter
                    <div class="ars-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    
    import PLATFORMS from '@/constants/platforms'

    export default {
        name: 'Authentication',
        data() {
            return {
                PLATFORMS,
                hasPendingRequest: false,
                form: {
                    email: '',
                    password: '',
                },
                error: null,
            }
        },
        computed: {
            platform: {
                get() {
                    return this.$store.state.currentPlatform
                },
                set(newPlatform) {
                    this.$store.commit('SET_PLATFORM', newPlatform)
                }
            },
        },
        methods: {
            ...mapActions('Auth', ['login']),
            async submit() {
                if (this.hasPendingRequest)
                    return

                this.hasPendingRequest = true
                this.error = null

                if (this.platform && this.form.email != '' && this.form.password != '') {
                    try {
                        await this.login(this.form)
                    } catch(err) {
                        this.error = "Email ou mot de passe incorrect !"
                        this.hasPendingRequest = false
                        return 
                    }
                    
                    this.$router.push({ name: 'Home' })
                    return
                } else if (!this.platform) {
                    this.error = 'Veuillez choisir une formation.'
                }

                this.hasPendingRequest = false
            },
        },
    }
</script>

<style lang="scss" scoped>

.authentication {
    background-color: rgb(247, 247, 247);

    .authentication-box {
        width: 40%;
        height: 90%;
        max-width: 672px;
        max-height: 960px;

        .authentication-logo {
            margin-bottom: 4%;
        }

        .authentication-form {
            padding: 0 7.4%;
            width: 80%;

            button, input {
                @apply block appearance-none;

                &:focus {
                    @apply outline-none;
                }
            }

            a {
                @apply block text-sm mt-1;
            }

            .authentication-inputs {
                width: 100%;

                input {
                    @apply w-full bg-transparent m-auto px-4 py-3 text-sm shadow my-4;
                } 
            }

            .authentication-submit {
                button:focus {
                    @apply shadow;
                }
            }
        }
    }
}

</style>
