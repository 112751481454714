<template>
	<div class="promotion-chooser mb-4 lg:w-80 lg:mb-0 lg:mr-6" v-if="promotions && promotions.length > 1">
		<v-select
		name="promotions"
		label="title"
		:clearable="false"
		:options="promotions"
		:value="selectedPromotion"
		@input="$emit('choose', $event)" />
	</div>
	<div v-else-if="!promotions || promotions.length <= 0">
		<p>Aucune promotion disponible</p>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'PromotionChooser',
		model: {
			prop: 'selectedPromotion',
			event: 'choose'
		},
		props: {
			selectedPromotion: {
				type: Object,
				required: false,
				default: null
			}
		},
		computed: {
			...mapState('Auth', {
				promotions(state) {
					if (!state.userInfo)
						return []

					return state.userInfo.promotions.map((data) => data.promotion)
				}
			})
		},
		watch: {
			promotions: {
				handler(promotions) {
					// Automatically select first promotion
					if (promotions && promotions.length > 0) {
						this.$emit('choose', promotions[0])
					}
				},
				immediate: true
			}
		}
	}
</script>

<style scoped lang="scss"></style>
