import gql from 'graphql-tag'

const GC_ADD_USER_SEQUENCE = gql`
mutation AddUserSequence($userSequence: promotion_sequence_insert_input!) {
	insert_promotion_sequence_one(object: $userSequence) {
		promotion_id
	}
}
`

const GC_UPDATE_USER_SEQUENCE = gql`
mutation UpdateUserSequence(
	$promotionId: uuid!,
	$sequenceId: uuid!,
	$userId: uuid!,
	$oldStartDate: date!,
	$userSequence: promotion_sequence_set_input!
) {
	update_promotion_sequence(where: {promotion_id: {_eq: $promotionId}, sequence_id: {_eq: $sequenceId}, user_id: {_eq: $userId}, start_date: {_eq: $oldStartDate}}, _set: $userSequence) {
		affected_rows
	}
}
`

export {
	GC_ADD_USER_SEQUENCE,
	GC_UPDATE_USER_SEQUENCE
}