<template>
    <div class="forgot-password absolute inset-0 flex items-center justify-center">
        <div class="forgot-password-box bg-white rounded-lg flex flex-col items-center justify-center">
            <img class="forgot-password-logo cursor-pointer" src="@/assets/images/logo.svg"
            @click="$router.push({ name: 'Auth' })">

            <div
            class="forgot-password-form flex flex-col items-center justify-evenly border border-blue rounded-md"
            @keyup.enter="submit">
                <h1 v-if="!showConfirmation" class="uppercase text-xs font-main-bold">Réinitialisation de mot de passe</h1>

                <div v-if="!showConfirmation" class="w-full">
                    <div class="forgot-password-inputs border border-blue border-opacity-20 rounded">
                        <input class="placeholder-gray border-b border-blue border-opacity-20" type="password" name="password" placeholder="Nouveau mot de passe" v-model="password">
                        <input class="placeholder-gray" type="password" name="password-confirmation" placeholder="Confirmation du mot de passe" v-model="passwordConfirmation">
                    </div>
                    <p
                    class="text-left text-red mt-1"
                    v-if="validationError">
                        {{validationError}}
                    </p>
                    <p
                    class="text-left text-red mt-1"
                    v-if="password.length > 0 && !isPasswordValid">
                        Les mots de passe ne correspondent pas
                    </p>
                </div>

                <div v-else>
                    <p>Merci !</p>
                    <p>Votre mot de passe a bien été réinitialisé.</p>

                    <router-link
                    class="forgot-password-home-button ars-button-blue mt-4"
                    :to="{ name: 'Auth' }"> 
                        Retour à l'accueil
                    </router-link>
                </div>

                <button
                v-if="!showConfirmation"
                type="submit" 
                class="forgot-password-submit ars-button-blue"
                :class="{
                    'loading': hasPendingRequest
                }"
                :disabled="hasPendingRequest || !isPasswordValid"
                @click.prevent="submit">
                    Valider
                    <div class="ars-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ResetPassword',
        props: {
            token: {
                type: String,
                required: true,
                default: null,
            },
        },
        data() {
            return {
                hasPendingRequest: false,
                showConfirmation: false,
                validationError: false,
                password: '',
                passwordConfirmation: '',
            }
        },
        watch: {
            token: {
                handler(token) {
                    if (!token) {
                        this.$router.push({ name: 'Auth' })
                        return
                    }
                },
                immediate: true,
            },
        },
        computed: {
            isPasswordValid() {
                // Check password values
                const password = (this.password ? this.password.trim() : null)
                const passwordConfirmation = (this.passwordConfirmation ? this.passwordConfirmation.trim() : null)

                if (!password || !passwordConfirmation)
                    return false

                return (password.length > 0 && password == passwordConfirmation)
            },
        },
        methods: {
            ...mapActions('Auth', ['resetPassword']),
            async submit() {
                if (this.hasPendingRequest || !this.isPasswordValid)
                    return

                this.hasPendingRequest = true

                this.validationError = false

                const result = await this.resetPassword({
                    token: this.token,
                    password: this.password,
                })

                if (result !== false) {
                    this.showConfirmation = true
                } else {
                    this.validationError = 'Token expiré.'
                }

                this.hasPendingRequest = false
            },
        },
    }
</script>

<style lang="scss" scoped>

.forgot-password {
    .forgot-password-box {
        width: 40%;
        height: 90%;
        max-width: 672px;
        max-height: 960px;

        .forgot-password-logo {
            height: 7.2%;
            margin-bottom: 8%;
        }

        .forgot-password-illus {
            height: 28%;
            margin-bottom: 8.7%;
        }

        .forgot-password-form {
            padding: 0 7.4%;
            width: 80%;
            height: 40%;

            button, input {
                @apply block appearance-none;

                &:focus {
                    @apply outline-none;
                }
            }

            .forgot-password-inputs {
                width: 100%;

                input {
                    @apply w-full bg-transparent m-auto px-4 py-3 text-sm;
                } 
            }

            .forgot-password-submit {
                button:focus {
                    @apply shadow;
                }
            }
        }
    }
}

</style>
