import axios from 'axios'

import router from '@/router/index'
import { apollo } from '@/apolloClient'

import { GC_USER_ID, GC_AUTH_TOKEN } from '@/constants/jwt'

import { GC_USER_INFOS, GC_USER_SEQUENCES } from '@/graphql/auth'

export default {
	namespaced: true,
	state: {
		user: null,
		userInfo: null,
		loggedIn: !!localStorage.getItem(GC_AUTH_TOKEN), //todo ??
		errorMessage: null
	},
	getters: {
		getErrorMessage(state) {
			return state.errorMessage
		},
		isLoggedIn(state) {
			return state.loggedIn
		}
	},
	mutations: {
		SET_USER(state, data) {
			if (data.user && data.token) {
				state.user = data.user
				
				localStorage.setItem(GC_AUTH_TOKEN, data.token)
				localStorage.setItem(GC_USER_ID, data.user.id)
				
				state.loggedIn = true //todo ??
			}
		},
		SET_USER_INFO(state, data) {
			const userInfo = data.users[0]

			state.userInfo = userInfo
			state.user = { id: userInfo.id }
		},
		SET_USER_SEQUENCES(state, data) {
			const sequences = (data.users && data.users.length > 0 ? data.users[0].sequences : null)

			if (sequences) {
				state.userInfo.sequences = sequences
			}
		},
	},
	actions: {
		async login({ rootState, state, commit }, data) {
			const platform = rootState.currentPlatform

			if (!platform) {
				return
			}

			state.errorMessage = ''
			
			let user = {
				email: data.email,
				password: data.password
			}

			const res = await axios.post(platform.apiURL + '/signin', user)

			if (!res)
				return

			if (res.data.error)
				state.errorMessage = res.data.error

			return commit('SET_USER', res.data)
		},
		logout({ rootState, state }) {
			localStorage.removeItem(GC_AUTH_TOKEN)
			localStorage.removeItem(GC_USER_ID)
			localStorage.removeItem('platform_slug')

			state.user = null
			state.userInfo = null
			state.loggedIn = false,
			rootState.currentPlatform = null

			router.push({ name: 'Auth' }).catch(err => err)
		},
		async forgotPassword({ rootState, state }, email) {
			state.errorMessage = ''

			try {
				const platform = rootState.currentPlatform

				if (!platform) {
					return false
				}

				const res = await axios.post(platform.apiURL + '/sendResetEmail', {
					email
				})
				
				return res.data
			} catch (error) {
				if (error.response)
					state.errorMessage = error.response.data.error
			}
			
			return false
		},
		async resetPassword({ rootState, state }, { password, token }) {
			state.errorMessage = ''

			try {
				const platform = rootState.currentPlatform

				if (!platform) {
					return false
				}

				const res = await axios.put(platform.apiURL + '/resetPassword', {
					newPassword: password,
					uid: token,
				})
				
				return res.data
			} catch (error) {
				if (error.response)
					state.errorMessage = error.response.data.error
			}
			
			return false
		},
		async getCurrentUserInfo({ commit, dispatch, state }) {
			const currentUserId = localStorage.getItem(GC_USER_ID)

			if (currentUserId && state.loggedIn) {
				try {
					const response = await apollo.query({
						query: GC_USER_INFOS,
						variables: {
							id: currentUserId
						}
					})

					commit('SET_USER_INFO', response.data)
				}
				catch (err) {
					dispatch('logout')
				}
			} else {
				dispatch('logout')
			}
		},
		async loadCurrentUserSequences({ commit, dispatch, state }) {
			const currentUserId = localStorage.getItem(GC_USER_ID)

			if (currentUserId && state.loggedIn) {
				try {
					const response = await apollo.query({
						query: GC_USER_INFOS,
						variables: {
							id: currentUserId
						}
					})

					commit('SET_USER_SEQUENCES', response.data)
				}
				catch (err) {
					dispatch('logout')
				}
			} else {
				dispatch('logout')
			}
		}
	}
}