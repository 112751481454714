import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'

import Authentication from '@/views/Authentication';
import ForgotPassword from '@/views/ForgotPassword';
import ResetPassword from '@/views/ResetPassword';
import Home from '@/views/Home';

Vue.use(VueRouter)

const routes = [
	{
		path: '/auth',
		name: 'Auth',
		component: Authentication,
		meta: {
			title: 'Authentication',
			noAuth: true,
		}
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword,
		meta: {
			title: 'Mot de passe oublié',
			noAuth: true,
		}
	},
	{
		path: '/reset-password/:token',
		name: 'ResetPassword',
		component: ResetPassword,
		props: true,
		meta: {
			title: 'Réinitialisation de mot de passe',
			noAuth: true,
		}
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Home',
			showMap: true,
			showUI: true,
			breadcrumb: {
				label: 'Carte',
				to: {
					name: 'Home',
				},
			},
		}
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// Authentication check
router.beforeEach(async (to, from, next) => {
	// Remove "loading" background on body if needed
	if (to.meta && to.meta.noBackground) {// todo: cleaner ?
		document.body.style.background = "black"
	} else {
		document.body.style.background = null
	}

	const needAuth = !to.meta.noAuth
	const loggedIn = store.getters['Auth/isLoggedIn']

	// Check the authentication status of the user
	if (!loggedIn) {
		if (needAuth) {
			return next({ name: 'Auth' })
		} else {
			return next()
		}
	}

	// Redirect to home if already logged in
	if (!needAuth) {
		return next('/')
	}

	// Handle first page load
	let userInfo = store.state.Auth.userInfo

	if (!userInfo) {
		await store.dispatch('Auth/getCurrentUserInfo')

		userInfo = store.state.Auth.userInfo

		// Check if we didn't get userInfo (can be null if token has expired)
		if (!userInfo) {
			return next({ name: 'Auth' })
		}
	}

	return next()
})

export default router