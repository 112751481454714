<template>
	<div class="sequence-date-chooser flex sm:items-center justify-center bg-white sm:bg-gray-overlay sm:rounded-2.5xl" @click="$emit('close')">
		<div v-if="!messageData" class="bg-white rounded-2.5xl sm:py-7 sm:px-8" @click.stop>
			<div class="flex justify-between mb-6">
				<div class="font-medium text-lg">
					<p>Choisir une date pour :</p>
					<p>Niveau {{level.order + 1}} — Séquence {{sequence.order + 1}}</p>
				</div>
				<div class="flex-shrink">
					<button class="h-4 w-4 sm:-mr-2" @click="$emit('close')">
						<img src="@/assets/images/cross-icon.svg">
					</button>
				</div>
			</div>
			
			<Datepicker
			v-if="promotionSequence"
			class="mb-10 sm:mb-7"
			:inline="true"
			:language="fr"
			:monday-first="true"
			:disabled-dates="disabledDates"
			:day-cell-content="customDayCellContent"
			v-model="date" />
			<p v-else>
				Cette séquence ne peut pas encore être programmée.
			</p>

			<button
			v-if="promotionSequence"
			class="ars-button-green mx-auto"
			:disabled="!isValidDate && !isLoading"
			@click="onValidateClick">Valider</button>
		</div>

		<div v-else class="bg-white rounded-2.5xl pt-28 sm:p-5 w-96">
			<p v-if="messageData.startDate">
				La séquence {{sequence.order + 1}} du niveau {{level.order + 1}} a été 
				<span v-if="messageData.oldStartDate">
					déplacée du <b>{{formatDate(messageData.oldStartDate)}}</b> 
				</span>
				<span v-else>
					programmée 
				</span>
				au <b>{{formatDate(messageData.startDate)}}</b>
			</p>
			<p v-else>
				Erreur lors du traitement de votre demande, merci de réessayer plus tard
			</p>
		</div>
	</div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker'
	import { fr } from 'vuejs-datepicker/dist/locale'

	export default {
		name: 'SequenceDateChooser',
		components: {
			Datepicker
		},
		props: {
			promotion: {
				type: Object,
				required: true
			},
			level: {
				type: Object,
				required: true
			},
			sequence: {
				type: Object,
				required: true
			},
			userSequencesByDate: {
				type: Object,
				required: true
			},
			userSequence: {
				type: Object,
				required: false,
				default: null
			},
			previousUserSequence: {
				type: Object,
				required: false,
				default: null
			},
			nextUserSequence: {
				type: Object,
				required: false,
				default: null
			}
		},
		data() {
			return {
				isLoading: false,
				date: null,
				fr,
				messageData: null
			}
		},
		computed: {
			isValidDate() {
				return (this.date ? true : false)
			},
			promotionSequence() {
				// Get promotion sequence data for this sequence
				const promotionSequence = this.promotion.sequences.find((promotionSequence) => {
					return (this.sequence.id == promotionSequence.sequence_id)
				})

				// Check dates
				if (!promotionSequence || !promotionSequence.start_date || !promotionSequence.end_date) {
					return null
				}

				return promotionSequence
			},
			disabledDates() {
				if (!this.promotionSequence)
					return {}

				// Get to and from limits from promotion sequence data
				const endOfYesterday = ((new Date()).setHours(0, 0, 0, 0) - 1)

				let to = new Date(this.promotionSequence.start_date)
				let from = new Date(this.promotionSequence.end_date)

				// Check if 'to' is before the previous user sequence start date
				const previousUserSequenceStartDate = (this.previousUserSequence ? new Date(this.previousUserSequence.start_date) : null)

				if (previousUserSequenceStartDate && to.getTime() < previousUserSequenceStartDate.getTime()) {
					to = previousUserSequenceStartDate
				}

				// Check if 'from' is after the next user sequence start date
				const nextUserSequenceStartDate = (this.nextUserSequence ? new Date(this.nextUserSequence.start_date) : null)
				
				if (nextUserSequenceStartDate && from.getTime() > nextUserSequenceStartDate.getTime()) {
					from = nextUserSequenceStartDate
				}

				// Make from limit inclusive
				from.setDate(from.getDate() + 1)

				// Check if 'to' is in the past
				if (to.getTime() < endOfYesterday) {
					to = new Date(endOfYesterday)
				}

				// Check if 'from' is before 'to' (sanity check, should not happen IRL)
				if (from.getTime() < to.getTime()) {
					from = to
				}

				// Disable dates with already 2 user sequences on them
				const dateKeys = Object.keys(this.userSequencesByDate)
				const dates = dateKeys.reduce((dates, dateKey) => {
					if (this.userSequencesByDate[dateKey].length > 1) {
						dates.push(new Date(dateKey))
					}

					return dates
				}, [])

				return {
					to,
					from,
					dates
				}
			}
		},
		methods: {
			async onValidateClick() {
				if (!this.isValidDate && !this.isLoading)
					return

				this.isLoading = true

				// Prepare data
				let data = {
					promotionId: this.promotion.id,
					sequenceId: this.sequence.id,
					startDate: this.date,
					endDate: new Date(this.promotion.end_date || this.date)
				}

				// Add old start date if it's in the future (ie. the new date will replace the current one)
				if (this.userSequence) {
					const oldStartDate = new Date(this.userSequence.start_date)
					const endOfDay = (new Date()).setHours(24, 0, 0, 0)
					const isFuture = (oldStartDate.getTime() > endOfDay)

					if (isFuture) {
						data.oldStartDate = oldStartDate
					}
				}

				// Add or update remote data
				let success = null

				try {
					if (data.oldStartDate) {
						success = await this.$store.dispatch('UserSequence/update', data)
					} else {
						success = await this.$store.dispatch('UserSequence/add', data)
					}
				} catch (err) {
					success = false

					// Display error
					this.messageData = {
						error: err
					}
				}

				this.isLoading = false

				if (success) {
					// Display confirmation message
					this.messageData = data

					// Refresh user sequences
					await this.$store.dispatch('Auth/loadCurrentUserSequences')
				}

				// Hide message after a few seconds and close
				setTimeout(() => {
					this.messageData = null
					this.$emit('close')
				}, 3000)
			},
			customDayCellContent(cell) {
				// Get the date key in the userSequencesByDate dictionnary for this cell
				const date = new Date(cell.timestamp)
				const parts = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
				const dateKey = parts.map((part) => ((part < 10 ? '0' : '') + part)).join('-')

				// Add a class to the cell if it already has 2 user sequences
				if (this.userSequencesByDate[dateKey] && this.userSequencesByDate[dateKey].length > 0) {
					if (this.userSequencesByDate[dateKey].length > 1) {
						return '<p class="full-day" data-msg="Vous avez déjà programmé deux séquences pour cette date">' + cell.date + '</p>'
					}

					return '<p class="half-day">' + cell.date + '</p>'
				}

				return cell.date
			},
			formatDate(date) {
				const parts = [date.getDate(), date.getMonth() + 1, date.getFullYear()]

				return parts.map((part) => ((part < 10 ? '0' : '') + part)).join('/')
			}
		}
	}
</script>

<style lang="scss">
.sequence-date-chooser {
	.cell.disabled {
		@apply bg-gray-alt text-gray-dark cursor-not-allowed;
	}

	.full-day, .half-day {
		@apply relative border-b-2 border-orange w-full h-full;
	}

	.full-day {
		@apply border-red;

		@screen sm {
			&[data-msg]:hover:after {
				@apply block absolute bottom-2/4 sm:left-full bg-white text-black border-2 border-red rounded-2.5xl p-4;
				content: attr(data-msg);
				line-height: normal;

				@screen lg {
					width: 300px;
				}
			}
		}
	}

	.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
		@apply bg-blue text-white border-0;
	}
}

.vdp-datepicker .vdp-datepicker__calendar {
	border: none;

	header {
		.next, .prev {
			&:after {
				border: none;
				margin: 0;
				background-size: 100%;
				width: 8px;
				height: 14px;
			}

			&.disabled:after {
				border: none;
				@apply opacity-40;
			}
		}

		.next:after {
			background: url("../../assets/images/arrow-right-icon.svg") no-repeat center;
		}

		.prev:after {
			background: url("../../assets/images/arrow-left-icon.svg") no-repeat center;
		}
	}

	.cell.selected {
		@apply bg-blue text-white border-0;
	}
}
</style>
