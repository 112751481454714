import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Import tailwind
import '@/assets/scss/_index.scss'

import VueApollo from 'vue-apollo'

import { apollo, createApolloClient } from './apolloClient'

import vSelect from 'vue-select'

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apollo,
})

Vue.config.productionTip = false

Vue.component('v-select', vSelect)

new Vue({
  router,
  apolloProvider,
  store,
  watch: {
    '$store.state.currentPlatform': {
      handler(platform) {
        if (platform) {
          localStorage.setItem('platform_slug', platform.slug)
          apolloProvider.defaultClient = createApolloClient()
        }
      },
      immediate: true,
    },
  },
  render: h => h(App)
}).$mount('#app')
