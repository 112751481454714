import 'isomorphic-unfetch'

import router from '@/router/index'
import store from '@/store/index'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

import { GC_AUTH_TOKEN } from '@/constants/jwt'
import PLATFORMS from "@/constants/platforms"

let client = null

function parseJwt (token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}

export function createApolloClient() {
	const storedPlateformSlug = localStorage.getItem('platform_slug')
	const storedPlateform = (storedPlateformSlug && PLATFORMS.find((platform) => (platform.slug === storedPlateformSlug)))

	if (!storedPlateform) {
		return
	}

	// HTTP connection to the API
	const httpLink = createHttpLink({
		uri: storedPlateform.gqlURL,
	})

	const middlewareLink = new ApolloLink((operation, forward) => {
		const token = localStorage.getItem(GC_AUTH_TOKEN)

		operation.setContext({
			headers: {
				authorization: token ? `Bearer ${token}` : ''
			}
		})
		
		return forward(operation);
	});

	const errorLink = onError( err => {
		if (err.response && err.response.errors && err.response.errors[0].extensions.code == "access-denied") {
			const token = localStorage.getItem(GC_AUTH_TOKEN)

			if (token) {
				const decodedToken = parseJwt(token)
				const currentTime = Date.now().valueOf() / 1000;

				if (decodedToken.exp < currentTime) {
					/* expired */
					store.dispatch('Auth/logout')
					router.push('/auth')
				}
			}
		}
	});

	const link = new ApolloLink.from([
		errorLink,
		middlewareLink,
		httpLink
		])

	const cache = new InMemoryCache()

	client = new ApolloClient({
		link: link,
		cache
	})

	client.defaultOptions = {
		query: {
			fetchPolicy: 'no-cache'
		}
	}

	return client
}

// Create client on init if needed
if (!client) {
	client = createApolloClient()
}

export { client as apollo }