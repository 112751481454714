export default [
	{
		slug: 'gi',
		title: 'Gardiens',
		apiURL: 'https://webhook.cooc-gardiens.walkingnerds.dev',
		gqlURL: 'https://back.cooc-gardiens.walkingnerds.dev/v1/graphql',
	},
	{
		slug: 'rs',
		title: 'Responsables de site',
		apiURL: 'https://cooc-rs.walkingnerds.dev/webhook',
		gqlURL: 'https://cooc-rs.walkingnerds.dev/hasura/v1/graphql',
	},
]