<template>
	<div class="sequences-container">
		<div class="sequences">
			<SequenceCard
			v-for="(sequence, index) in orderedSequences"
			:key="sequence.id"
			:sequence="sequence"
			:is-editable="index <= (lastUserSequenceIndex + 1)"
			:user-sequence="userSequencesById[sequence.id]"
			:sequence-end-log="userSequenceEndsById[sequence.id]"
			@edit="editedSequenceIndex = index" />
		</div>

		<SequenceDateChooser
		class="absolute inset-0"
		v-if="editedSequenceIndex !== null"
		:promotion="promotion"
		:level="level"
		:sequence="orderedSequences[editedSequenceIndex]"
		:user-sequences-by-date="userSequencesByDate"
		:user-sequence="userSequencesById[orderedSequences[editedSequenceIndex].id]"
		:previous-user-sequence="(editedSequenceIndex > 0 ? userSequencesById[orderedSequences[editedSequenceIndex - 1].id] : null)"
		:next-user-sequence="(editedSequenceIndex < (orderedSequences.length - 1) ? userSequencesById[orderedSequences[editedSequenceIndex + 1].id] : null)"
		@close="onCloseDateChooser" />
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import SequenceCard from "@/components/Agenda/SequenceCard"
	import SequenceDateChooser from "@/components/Agenda/SequenceDateChooser"

	export default {
		name: 'Sequences',
		components: {
			SequenceCard,
			SequenceDateChooser
		},
		props: {
			promotion: {
				type: Object,
				required: true
			},
			level: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				editedSequenceIndex: null
			}
		},
		computed: {
			...mapState('Auth', {
				userSequencesById(state) {
					if (!state.userInfo)
						return {}

					// Return last user sequence data for each sequence id
					return state.userInfo.sequences.reduce((dict, userSequence) => {
						if (userSequence.promotion_id == this.promotion.id) {
							dict[userSequence.sequence_id] = userSequence
						}

						return dict
					}, {})
				},
				userSequenceEndsById(state) {
					if (!state.userInfo)
						return {}

					// Return sequence end log for each sequence id
					return state.userInfo.logs.reduce((dict, log) => {
						if (log.data && log.data == this.promotion.id) {
							dict[log.data_key] = log
						}

						return dict
					}, {})
				}
			}),
			userSequencesByDate() {
				if (!this.userSequencesById)
					return {}

				// Return last user sequence data for each sequence id
				const keys = Object.keys(this.userSequencesById)

				return keys.reduce((dict, key) => {
					const userSequence = this.userSequencesById[key]

					if (!dict[userSequence.start_date]) {
						dict[userSequence.start_date] = []
					}

					dict[userSequence.start_date].push(userSequence)

					return dict
				}, {})
			},
			orderedSequences() {
				// Sort a copy of sequences array by order
				const sequences = this.level.sequences.slice().sort((a, b) => {
					return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
				})

				return sequences
			},
			lastUserSequenceIndex() {
				// Return the index of the last ordered sequence with a user selected date
				let lastIndex = -1

				for (let i = 0; i < this.orderedSequences.length; i++) {
					const id = this.orderedSequences[i].id
					
					if (!this.userSequencesById[id] && !this.userSequenceEndsById[id]) {
						break
					}

					lastIndex = i
				}

				return lastIndex
			}
		},
		watch: {
			promotion() {
				// Make sure date chooser is closed
				this.onCloseDateChooser()
			},
			level() {
				// Make sure date chooser is closed
				this.onCloseDateChooser()
			}
		},
		methods: {
			onCloseDateChooser() {
				this.editedSequenceIndex = null
			}
		} 
	}
</script>

<style scoped lang="scss">;
.sequences-container {
	@apply relative bg-gray-alt rounded-2.5xl sm:py-16 sm:px-14;

	@screen lg {
		min-height: 700px;
	}
}

.sequences {

	@screen sm {
		@apply grid gap-8;

		grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	}
}
</style>
