import gql from 'graphql-tag'

const GC_USER_INFOS = gql`
query getCurrentUser($id: uuid!) {
	users(where: {id: {_eq: $id}}) {
		id
		name
		first_name
		experienced
		promotions: promotion_users(where: {promotion: {type: {slug: {_eq: "individual_promotion"}}}}) {
			promotion {
				id
				title
				start_date
				end_date
				course {
					name
					levels {
						id
						order
						sequences {
							id
							title
							order
						}
					}
				}
				sequences(where: {user_id: {_is_null: true}}) {
					sequence_id
					start_date
					end_date
				}
			}
		}
		sequences: promotion_sequences(order_by: {start_date: asc}) {
			promotion_id
			sequence_id
			start_date
		}
		logs(where: {logType: {slug: {_eq: "sequence_end"}}}) {
			created_at
			data_key
			data(path: "$.promotion_id")
		}
	}
}
`

const GC_USER_SEQUENCES = gql`
query getUserSequences($id: uuid!) {
	users(where: {id: {_eq: $id}}) {
		sequences: promotion_sequences(order_by: {start_date: asc}) {
			promotion_id
			sequence_id
			start_date
		}
	}
}
`

export {
	GC_USER_INFOS,
	GC_USER_SEQUENCES
}