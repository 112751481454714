<template>
	<div class="home p-4 sm:p-10">
		<div class="home-header flex flex-col lg:flex-row items-center border-b pb-5 mb-4">
			<WelcomeHeader />
			<PromotionChooser v-model="selectedPromotion" />
			<button class="ars-button" @click="onLogoutClick">Déconnexion</button>
		</div>
		<Agenda v-if="selectedPromotion" :promotion="selectedPromotion" />
	</div>
</template>

<script>
	import WelcomeHeader from "@/components/WelcomeHeader"
	import Agenda from "@/components/Agenda"
	import PromotionChooser from "@/components/PromotionChooser"

	export default {
		name: 'Home',
		components: {
			WelcomeHeader,
			Agenda,
			PromotionChooser
		},
		data() {
			return {
				selectedPromotion: null
			}
		},
		methods: {
			onLogoutClick() {
				this.$store.dispatch('Auth/logout')
			}
		}
	}
</script>
